import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { GoPencil } from "react-icons/go";
import { useParams } from "react-router-dom";
import VehicleForm from "../../components/registrationForm/vehicleForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Select from "react-select";
import MiniDriverCard from "../../components/miniCard/miniDriverCard";
import Swal from "sweetalert2";

import { labelRemover } from "../../utils/objectManipulation";
import { chunk } from "../../utils/arrayManipulation";

import truckIcon from "../../assets/truck.jpeg";
import pickupIcon from "../../assets/pickup.jpeg";
import hatchIcon from "../../assets/hatch.jpeg";
import sedanIcon from "../../assets/sedan.jpeg";
import motoIcon from "../../assets/moto.jpeg";

import styles from "./styles.module.scss";

import routerAPI from "../../services/routerAPI";

function VehiclePage() {
  const { id } = useParams(); // id do veículo referente à página (vem da URL.);

  const [show, setShow] = useState(false); // booleano para abrir o modal.

  const [driversData, setDriversData] = useState([]); // array com todos os motoristas do banco.
  const [driversList, setDriversList] = useState([]); // lista de motoristas ligados ao veículo.
  const [miniCardDrivers, setMiniCardDrivers] = useState([]); // array no .map() do Carousel.
  const [addedDrivers, setAddedDrivers] = useState([]); // motoristas a serem adicionados ao veículo.
  const [vehicleData, setVehicleData] = useState([]); // dados do veículo da página

  function handleDataUpdate(newData) {
    setVehicleData(newData);
    setShow(false);
  }

  async function handleAddDrivers() {
    // adicionar veículos do select ao motorista.
    await routerAPI.registration.vehicleDocument
      .addDrivers(id, addedDrivers.drivers)
      .then((res) => {
        Swal.fire({
          titleText: "Sucesso",
          text: "Motorista(s) alterado(s) com êxito!",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um erro ao adicionar o(s) motorista(s), " + err.message,
          icon: "error",
        });
      });
    for (let cont in addedDrivers.drivers) {
      await routerAPI.registration.physicalPerson
        .addVehicles(addedDrivers.drivers[cont], id)
        .then((res) => {
          console.log("êxito!");
        })
        .catch((err) => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um erro ao adicionar o veículo" + err.message,
            icon: "error",
          });
        });
    }
  }

  async function setVehiclesDrivers(vehicleData, driversIDs) {
    const tempDrivers = []; // tempDrivers = array de veículos a serem exibidos;

    for (let j in vehicleData) {
      if (
        driversIDs.drivers.find(
          (element) => element.toString() === vehicleData[j].id.toString()
        )
      ) {
        tempDrivers.push({
          name: vehicleData[j].name,
          cpf: vehicleData[j].cpf,
          email: vehicleData[j].contact.email,
        });
      }
    }

    setMiniCardDrivers(chunk(tempDrivers, 2));
  }

  function handleSelectChange(selectedOptions) {
    var drivers = labelRemover(selectedOptions);
    setAddedDrivers({ ...addedDrivers, drivers });
  }

  useEffect(() => {
    async function loadVehicleData(vehicleData) {
      var driversIDs = { drivers: [] };

      await routerAPI.listing.vehicle // Carrega os dados do veículo da página.
        .pull(id)
        .then((res) => {
          setVehicleData(res.data);

          for (let i in res.data.drivers) {
            // e já salva os ids de motorista que ele contém para uso futuro
            driversIDs.drivers.push(res.data.drivers[i]);
          }
        })
        .catch((err) => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao carregar os dados do veículo",
            icon: "error",
          });
        });

      setVehiclesDrivers(vehicleData, driversIDs);
    }

    async function loadDriversData() {
      let driversIDs = [];
      let vehicleData = [];
      await routerAPI.listing.driver
        .search()
        .then((res) => {
          for (let i in res.data) {
            driversIDs.push({
              // opções Select
              value: res.data[i].id,
              label: res.data[i].name,
            });

            vehicleData.push(res.data[i]);
          }

          setDriversList(driversIDs);
          setDriversData(vehicleData);
        })
        .catch((err) =>
          Swal.fire({
            titleText: "Erro",
            text:
              "Houve um problema ao carregar os motoristas do veículo," + err,
            icon: "error",
          })
        );

      loadVehicleData(vehicleData);
    }

    loadDriversData();
  }, [id]);

  function switchPicture(param) {
    let str = "";

    if (param !== undefined) str = param.toLowerCase();

    switch (str) {
      case "caminhão":
        return truckIcon;
      case "pickup":
        return pickupIcon;
      case "hatch":
        return hatchIcon;
      case "sedan":
        return sedanIcon;
      case "moto":
        return motoIcon;
      default:
    }
  }

  return (
    <Container fluid className={styles.pageContainer}>
      <button className={styles.modalButton} onClick={() => setShow(true)}>
        <GoPencil className={styles.icon} />
      </button>
      <Row className={styles.topInfo}>
        <Col className={styles.vehicleCol}>
          <Row>
            <Col xs={5} className={styles.infoContainer}>
              <VehicleForm
                loadedValues={vehicleData}
                show={show}
                setShow={setShow}
                editing={true}
                dataUpdate={handleDataUpdate}
              />
              <Col className={styles.infoContainer}>
                <Col xs={6} className={styles.pictureCol}>
                  <img
                    alt=""
                    className={styles.profilePic}
                    src={switchPicture(vehicleData.type)}
                  />
                </Col>
                <Col xs={5} className={styles.pictureCol}>
                  <p className={styles.driverName}>{vehicleData.brand_model}</p>
                  <div className={styles.cardText}>
                    <Icon icon="bx:bxs-user" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {vehicleData.name != null ? vehicleData.name : "N/A"}
                    </p>
                  </div>
                  <div className={styles.cardText}>
                    <Icon icon="la:credit-card" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {vehicleData.licensePlate != null
                        ? vehicleData.licensePlate
                        : "N/A"}
                    </p>{" "}
                  </div>{" "}
                  <div className={styles.cardText}>
                    <Icon icon="carbon:spray-paint" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {vehicleData.color != null
                        ? vehicleData.color
                        : "N/A"}{" "}
                      &nbsp;
                    </p>{" "}
                    <Icon icon="ant-design:calendar-twotone" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {vehicleData.model_year != null
                        ? vehicleData.model_year
                        : "N/A"}{" "}
                      &nbsp;
                    </p>{" "}
                  </div>
                </Col>
              </Col>
            </Col>

            <Col className={styles.mediumCol}>
              <Row className={styles.driversRow}>
                <Carousel variant="dark" className={styles.carousel} fade>
                  {miniCardDrivers.map((chunk1, idx) => (
                    <Carousel.Item key={idx} className={styles.carouselItem}>
                      <div className={styles.carouselBackground}>
                        <Row className={styles.carouselCardContainer}>
                          {!chunk1 ? (
                            <p>Nenhum motorista carregado.</p>
                          ) : (
                            chunk1.map((item, index) => {
                              return (
                                <Col
                                  key={index}
                                  className={
                                    index % 2
                                      ? styles.cardRight
                                      : styles.cardLeft
                                  }
                                >
                                  <MiniDriverCard
                                    name={item.name}
                                    cpf={item.cpf}
                                    email={item.email}
                                  />
                                </Col>
                              );
                            })
                          )}
                        </Row>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
              <Container>
                {" "}
                <p className={styles.label}> Alterar motoristas: </p>
                <Select
                  isMulti
                  options={driversList}
                  onChange={(selectedOptions) => {
                    handleSelectChange(selectedOptions);
                  }}
                ></Select>
                <Row xs={12} className={styles.buttonRow}>
                  <Button
                    className={styles.addButton}
                    onClick={() => {
                      setVehiclesDrivers(driversData, addedDrivers);

                      handleAddDrivers();
                    }}
                  >
                    Salvar
                  </Button>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default VehiclePage;
