// PESSOAS
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const phoneRegex =
  /^(\(?[0-9]{2}\)?)? ?([0-9]{4,5})-?([0-9]{4})$/i;

export const dateRegex =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;

export const CEPRegex = /^\d{5}-\d{3}$/i;

export const CNHRegex = /[A-Za-z0-9]{11}$/i; //RUDIMENTAR!

export const RGRegex = /(^\d{1,2}).?(\d{3}).?(\d{3})-?(\d{1}|X|x$)?/i;

// VEÍCULOS
export const vehicleDocRegex = /^(\d{10}|\d{12})$/i;

export const licensePltRegex = /[A-Z]{3}-?[0-9][0-9A-Z][0-9]{2}/i;
