import React from "react";

import { Row, Col, Card } from "react-bootstrap";

import styles from "./styles.module.scss";

function MiniDriverCard({ name, cpf, email }) {
  return (
    <Card className={styles.card}>
      <Card.Body className={styles.cardBody}>
        <Row className={styles.cardRow}>
          <Col className={styles.picArea}>
            <img
              alt=""
              className={styles.profilePic}
              src="https://thispersondoesnotexist.com/image"
            />
          </Col>

          <Col xs={8} className={styles.cardText}>
            <Row>
              <p className={styles.cardTitle}>{name} </p>
            </Row>
            <p className={styles.yearColor}>
              {cpf}
            </p>

            <p className={styles.yearColor}>
              {email}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default MiniDriverCard;
