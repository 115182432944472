import {
  categories_data,
  states_data,
  blood_type_data,
  marital_status_data,
} from "../components/registrationForm/selectData";

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Transforma um array num novo, dividido em "mini-arrays" com o tamanho enviado como parâmetro
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export function chunk(array, size) {
  const result = [];

  while (array.length) {
    result.push(array.splice(0, size));
  }

  return result;
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Com base no valor recebido, e num id para identificar o array a ser consultado,
// retorna o 'label' associado ao 'valor'.
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export function getLabel(val, id) {
  let options = [];
  switch (id) {
    case "1": {
      options = categories_data;
      break;
    }
    case "2": {
      options = states_data;
      break;
    }
    case "3": {
      options = blood_type_data;
      break;
    }
    case "4": {
      options = marital_status_data;
      break;
    }
    default:
  }

  for (let i in options) {
    if (options[i].value === val) {
      return options[i].label;
    }
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Retorna um array de "getLabels", para múltiplas opções
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export function getSelectMultiLabel(values) {
  var multiLabel = [];

  // por enquanto apenas

  for (let i in values) {
    multiLabel.push({ value: values[i], label: getLabel(values[i], "1") });
  }

  return multiLabel;
}

export function getLabelArray(values) {
  var multiLabel = getSelectMultiLabel(values);

  multiLabel.map((item, index) => {
    return item;
  });
}
