import React, { useState, useEffect } from "react";

import { Container, Row } from "react-bootstrap";
import DriverCards from "../../components/driverCard/index.js";
import { ImPlus } from "react-icons/im";
import DriverForm from "../../components/registrationForm/driverForm.js";
import Swal from "sweetalert2";

import styles from "./styles.module.scss";

import RouterAPI from "../../services/routerAPI";

function Drivers() {
  // Galeria de ./DriverCards, e onde se faz o cadastro de novo motorista;
  const [driverData, setDriverData] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    RouterAPI.listing.driver
      .search()
      .then((res) => {
        setDriverData(res.data);
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um erro ao carregar os motoristas, " + err.message,
          icon: "error",
        });
      });
  }, []);

  const preloadedValues = {
    ch_type: "DRIVER",
    is_organ_donor: false,
    marital_status: "",
    blood_type: "",
    state: "",
    vehicles: [],
  };

  return (
    <Container fluid className={styles.pageContainer}>
      <button className={styles.modalButton} onClick={() => setShow(true)}>
        <ImPlus className={styles.icon} />
      </button>

      <Row className={styles.cardGalery}>
        {driverData.map((item, index) => {
          console.log("achei o " + item.name);
          return <DriverCards item={item} key={index} userId={item.id} />;
        })}
      </Row>

      <DriverForm
        isNewDriver={true}
        show={show}
        setShow={setShow}
        loadedValues={preloadedValues}
      />
    </Container>
  );
}

export default Drivers;
