import React, { useState, useEffect } from "react";

import { Icon } from "@iconify/react";
import { GoPencil } from "react-icons/go";
import { useParams } from "react-router-dom";
import DriverForm from "../../components/registrationForm/driverForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Select from "react-select";
import MiniVehicleCard from "../../components/miniCard/miniVehicleCard";
import Swal from "sweetalert2";

import { labelRemover } from "../../utils/objectManipulation.js";
import { chunk } from "../../utils/arrayManipulation.js";

import styles from "./styles.module.scss";

import routerAPI from "../../services/routerAPI";

function UserPage() {
  const { id } = useParams();

  const [show, setShow] = useState(false);

  const [vehiclesData, setVehiclesData] = useState([]); // array com todos os veículos
  const [vehiclesList, setVehiclesList] = useState([]); // options do select de veículos
  const [miniCardVehicles, setMiniCardVehicles] = useState([]); // veículos a serem exibidos no Carousel
  const [addedVehicles, setAddedVehicles] = useState([]); // veículos escolhidos no select
  const [driverData, setDriverData] = useState({}); // Dados do motorista carregado.

  function handleSelectChange(selectedOptions) {
    var vehicles = labelRemover(selectedOptions);
    setAddedVehicles({ ...addedVehicles, vehicles });
  }

  function handleDataUpdate(newData) {
    setDriverData(newData);
    setShow(false);
  }

  async function handleAddVehicles() {
    // adicionar veículos do select ao motorista.
    await routerAPI.registration.physicalPerson
      .addVehicles(id, addedVehicles.vehicles)
      .then((res) => {
        Swal.fire({
          titleText: "Sucesso",
          text: "Veículo(s) alterado(s) com êxito!",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          titleText: "Erro",
          text: "Houve um erro ao adicionar os veículos" + err.message,
          icon: "error",
        });
      });
    for (let cont in addedVehicles.vehicles) {
      await routerAPI.registration.vehicleDocument
        .addDrivers(addedVehicles.vehicles[cont], id)
        .then((res) => {
          console.log("êxito!");
        })
        .catch((err) => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um erro ao adicionar o motorista" + err.message,
            icon: "error",
          });
        });
    }
  }

  async function setDriversVehicles(driverData, vehiclesIDs) {
    // cria as opções para o Select e carrega os veículos do Carousel
    const tempVehicles = [];

    for (let j in driverData) {
      if (
        vehiclesIDs.vehicles.find(
          (element) => element.toString() === driverData[j].id.toString()
        )
      ) {
        tempVehicles.push({
          // veículos Carousel
          brand_model: driverData[j].brand_model,
          licensePlate: driverData[j].licensePlate,
          model_year: driverData[j].model_year,
          color: driverData[j].color,
        });
      }
    }

    setMiniCardVehicles(chunk(tempVehicles, 2));
  }

  useEffect(() => {
    async function loadDriverData(driverData) {
      var vehiclesIDs = { vehicles: [] };

      await routerAPI.listing.driver // Carrega os dados do motorista da página.
        .pull(id)
        .then((res1) => {
          setDriverData(res1.data);

          for (let i in res1.data.vehicles) {
            // e já salva os ids de veículo que ele contém para uso futuro
            vehiclesIDs.vehicles.push(res1.data.vehicles[i]);
          }
        })
        .catch((err) => {
          Swal.fire({
            titleText: "Erro",
            text: "Houve um problema ao carregar os dados do motorista," + err,
            icon: "error",
          });
        });

      setDriversVehicles(driverData, vehiclesIDs);
    }

    async function loadVehiclesData() {
      let vehiclesIDs = [];
      let driverData = [];
      await routerAPI.listing.vehicle
        .search()
        .then((res) => {
          for (let i in res.data) {
            vehiclesIDs.push({
              // opções Select
              value: res.data[i].id,
              label: res.data[i].brand_model,
            });

            driverData.push(res.data[i]);
          }

          setVehiclesList(vehiclesIDs);
          setVehiclesData(driverData);
        })
        .catch();

      loadDriverData(driverData);
    }

    loadVehiclesData();
  }, [id]);

  return (
    <Container fluid className={styles.pageContainer}>
      <button className={styles.modalButton} onClick={() => setShow(true)}>
        <GoPencil className={styles.icon} />
      </button>
      <Row className={styles.topInfo}>
        <Col className={styles.userCol}>
          <Row>
            <Col xs={5} className={styles.infoContainer}>
              <DriverForm
                loadedValues={driverData}
                show={show}
                setShow={setShow}
                editing={true}
                dataUpdate={handleDataUpdate}
              />
              <Col className={styles.infoContainer}>
                <Col xs={6} className={styles.pictureCol}>
                  <img
                    alt=""
                    className={styles.profilePic}
                    src="https://thispersondoesnotexist.com/image"
                  />
                </Col>
                <Col xs={5} className={styles.pictureCol}>
                  <p className={styles.driverName}>{driverData.name}</p>
                  <div className={styles.cardText}>
                    <Icon icon="mdi:email" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {driverData.contact != null
                        ? driverData.contact.email
                        : "N/A"}
                    </p>
                  </div>
                  <div className={styles.cardText}>
                    <Icon icon="heroicons-solid:phone" />
                    <p className={styles.cardLabel}>
                      {" "}
                      &nbsp;
                      {driverData.contact != null
                        ? driverData.contact.num_phone
                        : "N/A"}
                    </p>
                  </div>
                </Col>
              </Col>
            </Col>

            <Col className={styles.mediumCol}>
              <Row className={styles.vehiclesRow}>
                <Carousel variant="dark" className={styles.carousel} fade>
                  {miniCardVehicles.map((chunk1, idx) => (
                    <Carousel.Item key={idx} className={styles.carouselItem}>
                      <div className={styles.carouselBackground}>
                        <Row className={styles.carouselCardContainer}>
                          {chunk1.map((item, index) => {
                            return (
                              <Col
                                key={index}
                                className={
                                  index % 2 ? styles.cardRight : styles.cardLeft
                                }
                              >
                                <MiniVehicleCard
                                  brand_model={item.brand_model}
                                  licensePlate={item.licensePlate}
                                  model_year={item.model_year}
                                  color={item.color}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Row>
              <Container>
                {" "}
                <p className={styles.label}> Alterar veículos: </p>
                <Select
                  isMulti
                  options={vehiclesList}
                  onChange={(selectedOptions) => {
                    handleSelectChange(selectedOptions);
                  }}
                ></Select>
                <Row xs={12} className={styles.buttonRow}>
                  <Button
                    className={styles.addButton}
                    onClick={() => {
                      setDriversVehicles(vehiclesData, addedVehicles);
                      handleAddVehicles();
                    }}
                  >
                    Salvar
                  </Button>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default UserPage;
