import React from "react";

import { FaHome } from "react-icons/fa";
import { GiSteeringWheel } from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";

export const SideMenuData = [ // opções do menu lateral
  {
    title: "Home",
    path: "/",
    icon: <FaHome />,
  },
  {
    title: "Motoristas",
    path: "/drivers",
    icon: <GiSteeringWheel />,
  },
  {
    title: "Frota",
    path: "/vehicles",
    icon: <AiFillCar />,
  },
];
