export const login = (token) => {
  localStorage.setItem("jwt", token);
};

export const logout = () => {
  //remove do LocalStorage o TOKEN para testes, como se finalizasse a sessão um user;
  localStorage.removeItem("jwt");
  window.location.reload();
};

export const isLogin = () => {
  if (localStorage.getItem("jwt") !== undefined) {
    //verifica se "o usuário está logado"
    return true;
  }

  return false;
};
