export const categories_data = [
  { value: "a", label: "A" },
  { value: "b", label: "B" },
  { value: "c", label: "C" },
  { value: "d", label: "D" },
  { value: "e", label: "E" },
];

export const states_data = [
  { value: "ac", label: "AC" },
  { value: "al", label: "AL" },
  { value: "am", label: "AM" },
  { value: "ba", label: "BA" },
  { value: "ce", label: "CE" },
  { value: "es", label: "ES" },
  { value: "go", label: "GO" },
  { value: "ma", label: "MA" },
  { value: "mt", label: "MT" },
  { value: "ms", label: "MS" },
  { value: "mg", label: "MG" },
  { value: "pa", label: "PA" },
  { value: "pb", label: "PB" },
  { value: "pr", label: "PR" },
  { value: "pe", label: "PE" },
  { value: "pi", label: "PI" },
  { value: "rj", label: "RJ" },
  { value: "rn", label: "RN" },
  { value: "rs", label: "RS" },
  { value: "ro", label: "RO" },
  { value: "rr", label: "RR" },
  { value: "sc", label: "SC" },
  { value: "sp", label: "SP" },
  { value: "to", label: "TO" },
  { value: "df", label: "DF" },

];

export const blood_type_data = [
  { value: "a+", label: "A+" },
  { value: "a-", label: "A-" },
  { value: "b+", label: "B+" },
  { value: "b-", label: "B-" },
  { value: "ab+", label: "AB+" },
  { value: "ab-", label: "AB-" },
  { value: "o+", label: "O+" },
  { value: "o-", label: "O-" },
];

export const marital_status_data = [
  { value: "solteiro", label: "Solteiro(a)" },
  { value: "casado", label: "Casado(a)" },
  { value: "divorciado", label: "Divorciado(a)" },
  { value: "viuvo", label: "Viúvo(a)" },
  { value: "separado_judicialmente", label: "Separado(a) Judicialmente" },
];
