import React from "react";

import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import RequiredTag from "../requiredTag/index.js";
import Register from "../register/index.js"; //upload de foto

import { emailRegex, phoneRegex, dateRegex } from "../../utils/regex.js";
import { cpfMask, dateMask, telMask } from "../../utils/maskedInputs";
import { getLabel } from "../../utils/arrayManipulation";
import { isCPFValid } from "../../utils/validation";
import { marital_status_data, blood_type_data } from "./selectData";

import styles from "./styles.module.scss";

export default function PersonalForm({
  // Formulário a ser utilizado no ./driverForm, especificamente a parte de dados pessoais.
  register,
  control,
  errors,
  isNew,
  getValues,
}) {
  return (
    <div className={styles.new}>
      <Row className={styles.dividedRow}>
        <Col className={styles.picColumn} xs={3}>
          <Register />
        </Col>

        <Col xs={8} className={styles.contactColumn}>
          <Row className={styles.dividedRow}>
            <Form.Group as={Col} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Nome:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("name", { required: "O nome é obrigatório." })}
              />

              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>
          <Row className={styles.dividedRow}>
            <Row className={styles.emailInputRow}>
              <Form.Group as={Col} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>E-mail:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  placeholder="exemplo@email.com"
                  {...register("contact.email", {
                    required: "Email é obrigatório.",
                    pattern: {
                      value: emailRegex,
                      message: "E-mail inválido.",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="contact.email"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>
            </Row>

            <Row className={styles.mediumInputRow}>
              <Form.Group as={Col} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>Tel:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="tel"
                  placeholder="(DDD) 99999-9999 "
                  //onChange={({ target }) => setTelInput(target.value)}
                  {...register("contact.num_phone", {
                    required: "Telefone é obrigatório.",
                    pattern: {
                      value: phoneRegex,
                      message: "Telefone inválido.",
                    },
                  })}
                  onChange={({ target }) => {
                    const { value } = target;
                    target.value = telMask(value);
                  }}
                />

                <ErrorMessage
                  errors={errors}
                  name="contact.num_phone"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row className={styles.dividedRow}>
        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Nascido em:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              placeholder="DD/MM/AAAA"
              {...register("birth_data", {
                required: "Data é obrigatória.",
                pattern: {
                  value: dateRegex,
                  message: "Data inválida.",
                },
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = dateMask(value);
              }}
            />
            <ErrorMessage
              errors={errors}
              name="birth_data"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>CPF:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              placeholder="000.000.000-11"
              {...register("cpf", {
                required: "CPF é obrigatório.",
                validate: (value) => isCPFValid(value) || "CPF inválido.",
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = cpfMask(value);
              }}
            />

            <ErrorMessage
              errors={errors}
              name="cpf"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
        <Row className={styles.mediumInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Nome mãe:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              required
              type="text"
              {...register("mother_name", {
                required: "Nome da mãe é obrigatório.",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="mother_name"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
      </Row>

      <Row>
        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Estado civil:</Form.Label>
              <RequiredTag />
            </div>
            <Controller
              control={control}
              {...register("marital_status", {
                required: "Estado civil é obrigatório.",
              })}
              ref={null}
              render={({ field: { onChange, name } }) => (
                <Select
                  placeholder="Selecione uma opção..."
                  name={name}
                  options={marital_status_data}
                  className="basic-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    onChange(selectedOption.value);
                  }}
                  defaultValue={
                    isNew
                      ? {
                          label: getValues("marital_status"),
                          value: getValues("marital_status"),
                        }
                      : {
                          label: getLabel(getValues("marital_status"), "4"),
                          value: getValues("marital_status"),
                        }
                  }
                />
              )}
              name="marital_status"
            />
            <ErrorMessage
              errors={errors}
              name="marital_status"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>
                Tipo sanguíneo:
              </Form.Label>
              <RequiredTag />
            </div>
            <Controller
              control={control}
              {...register("blood_type", {
                required: "Tipo sanguíneo é obrigatório.",
              })}
              ref={null}
              render={({ field: { onChange, name } }) => (
                <Select
                  placeholder="Selecione uma opção..."
                  name={name}
                  defaultValue={
                    isNew
                      ? {
                          label: getValues("blood_type"),
                          value: getValues("blood_type"),
                        }
                      : {
                          label: getLabel(getValues("blood_type"), "3"),
                          value: getValues("blood_type"),
                        }
                  }
                  options={blood_type_data}
                  className="basic-select"
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    onChange(selectedOption.value);
                  }}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="blood_type"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
        <Row className={styles.checkBoxRow}>
          <Form.Group as={Col} className={styles.inputCheckbox}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>
                Doador de órgãos?
              </Form.Label>
              <RequiredTag />
            </div>
            <Controller
              name="is_organ_donor"
              control={control}
              render={({ field }) => (
                <Form.Check
                  {...field}
                  inline
                  type="checkbox"
                  className={styles.checkbox}
                  checked={getValues("is_organ_donor")}
                />
              )}
            />
          </Form.Group>
        </Row>
      </Row>
    </div>
  );
}
