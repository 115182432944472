function Home() {
  return (
    <div className="Home">
      <header className="HomeHeader">
        <h1> HOME </h1>
      </header>
    </div>
  );
}

export default Home;
