function onlyNumbers(value) {
  let valor = 0;
  if (value !== "" && value !== null && value !== undefined) {
    valor = value.toString().replace(/[^0-9]/g, "");
  }
  return valor;
}

function cpfMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

function telMask(value) {
  if (value || false) {
    const onlyDigits = value.replace(/[^0-9]/g, "");
    let searchPrimeiroSegmento = /(\d{4})(\d)/;
    if (onlyDigits.length > 10) {
      searchPrimeiroSegmento = /(\d{5})(\d)/;
    }

    return (
      onlyDigits
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(searchPrimeiroSegmento, "$1-$2")
        //.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, "$1")
    );
  }

  return null;
}

function cepMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

function plateMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/(\D{3})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
}

function rgMask(value) {
  if (!value) {
    return "";
  }
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{1})\d+?$/, "$1");
}

function cnhMask(value) {
  if (!value) {
    return "";
  }
  return value.replace(/\D/g, "").replace(/(\d{11})\d+?$/, "$1");
}

function dateMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
}

const validaCPF = function (strCPF) {
  strCPF = strCPF.replace(/\D/g, "");
  let soma = 0;
  let resto;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export { onlyNumbers, cepMask, cnhMask, cpfMask, dateMask, rgMask, telMask, validaCPF, plateMask };
