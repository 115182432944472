import React, { useState } from "react";

import { NavLink } from "react-router-dom";

import { SideMenuData } from "./sideMenuData";

import styles from "./styles.module.scss";

function SideMenu() {
  const [sidebar, setSidebar] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const showSidebar = () => setSidebar(!sidebar);

  function handleSelected(index) {
    
    setSelectedIndex(index);
    return;
  }

  return (
    <>
      {" "}
      <nav className={styles.sideMenu}>
        {" "}
        <ul className={styles.menuItems}>
          {" "}
          {SideMenuData.map((item, index) => {
            //função "map" vai percorrer e renderizar cada item do SideMenuData.js
            return (
              <li
                key={index}
                className={styles.menuContent}
                onClick={sidebar ? showSidebar : null}
              >
                <NavLink
                  to={item.path}
                  onClick={() => handleSelected(index)}
                  className={
                    selectedIndex === index ? styles.selected : styles.disabled
                  }
                >
                  <span className={styles.menuIcons}>{item.icon}</span>
                  <span className={styles.menuText}>{item.title}</span>{" "}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default SideMenu;
