import React from "react";

import { useNavigate, generatePath } from "react-router-dom";
import { Col, Card } from "react-bootstrap";

import { Icon } from "@iconify/react";

import styles from "./styles.module.scss";

function DriverCards({ item }) {
  let navigate = useNavigate();

  const handleProceed = (e, index) => {
    navigate(generatePath(`/userPage/${item.id}`));
  };

  return (
    <Col
      className={styles.cardCol}
      xs={12}
      sm={9}
      md={6}
      lg={5}
      xl={3}
      xxl={3}
    >
      <Card onClick={(e) => handleProceed(e, item.id)} className={styles.card}>
        <Card.Img
          variant="top"
          className={styles.cardBanner}
          src="https://images.wallpaperscraft.com/image/single/texture_relief_3d_156496_1600x900.jpg"
        />
        <Card.Body>
          <img
            alt=""
            className={styles.cardPicture}
            src="https://thispersondoesnotexist.com/image"
          />
          <h3 className={styles.cardTitle}>{item.name}</h3>
          <div className={styles.cardContent}>
            <div className={styles.cardText}>
              <Icon icon="heroicons-solid:identification" />
              <span> {item.cpf} </span>
            </div>
            <div className={styles.cardText}>
              <Icon icon="heroicons-solid:phone" />
              <span> {item.contact.num_phone} </span>
            </div>
            <div className={styles.cardText}>
              <Icon icon="heroicons-solid:mail" />
              <span> {item.contact.email} </span>
            </div>
            <div className={styles.cardText}>
              <Icon icon="heroicons-solid:location-marker" />
              <span>
                {"  "}
                {item.address.city}, {item.address.state.label}
              </span>
            </div>
            <div className={styles.cardText}>
              <Icon icon="ri:steering-2-line" />
              <span>
                {"  "}
                {item.qualifications_types.map((type, cnhIndex) => {
                  return <span key={cnhIndex}>{type} </span>;
                })}
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default DriverCards;
