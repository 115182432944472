import { BrowserRouter, Routes, Route } from "react-router-dom";
// import PrivateRoute from "./routes/privateRoute/index.js";  // Comentados para posteriormente fazer a
// import PublicRoute from "./routes/publicRoute/index.js";    // restrição de páginas autenticadas
import { Fragment } from "react";
import SideMenu from "./components/sideMenu/index";

import Home from "./pages/Home/index";
import Drivers from "./pages/Drivers/index";
import Vehicles from "./pages/Vehicles/index";
import UserPage from "./pages/userPage/index.js";
import Login from "./pages/Login/index";
import NavBar from "./components/navBar";
import VehiclePage from "./pages/vehiclePage/index.js";

import { isLogin } from "./utils/login.js";

function App() {
  return (
    <>
      <BrowserRouter>
        {isLogin && (
          <Fragment>
            <NavBar />
            <SideMenu />
          </Fragment>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* telas contendo navBar/sideMenu */}

          {/* <Fragment>
              <NavBar />
              <SideMenu />
            </Fragment> */}

          <Route path="/" element={<Home />} />
          <Route path="/drivers" element={<Drivers />} />
          {/* galeria/cadastro de motoristas */}
          <Route path="/vehicles" element={<Vehicles />} />
          {/* galeria/cadastro de veículos */}
          <Route path="/userPage/:id" element={<UserPage />} />
          {/* página do motorista */}
          <Route path="/vehiclePage/:id" element={<VehiclePage />} />
          {/* página do veículo */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
