import React from "react";

import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import RequiredTag from "../requiredTag";

import { CNHRegex, dateRegex, RGRegex } from "../../utils/regex";
import { cnhMask, dateMask, rgMask } from "../../utils/maskedInputs";
import { labelRemover } from "../../utils/objectManipulation.js";
import { getSelectMultiLabel } from "../../utils/arrayManipulation.js";
import { categories_data } from "./selectData";

import styles from "./styles.module.scss";

export default function DocForm({
  // Formulário a ser utilizado no ./driverForm, especificamente a parte de documentação.
  register,
  control,
  errors,
  getValues,
}) {
  return (
    <div className={styles.new}>
      <Row className={styles.dividedRow}>
        <h1 className={styles.title}>CNH</h1>

        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>
                Nº de Registro:
              </Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              placeholder="00000000001"
              className={styles.inputLarge}
              {...register("qualifications.register_number", {
                pattern: {
                  value: CNHRegex,
                  message: "Número de registro inválido",
                },
                required: "Número de registro é obrigatório.",
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = cnhMask(value);
              }}
            />

            <ErrorMessage
              errors={errors}
              name="qualifications.register_number"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
        <Row className={styles.bigInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Categoria:</Form.Label>
              <RequiredTag />
            </div>
            <Controller
              control={control}
              {...register("qualifications_types", {
                required: "Categoria é obrigatória.",
              })}
              ref={null}
              render={({ field: { onChange, name } }) => (
                <Select
                  isMulti
                  placeholder="Selecione..."
                  name={name}
                  options={categories_data}
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    var finalOption;
                    finalOption = labelRemover(selectedOption);
                    onChange(finalOption);
                  }}
                  className={styles.selectMulti}
                  hideSelectedOptions={false}
                  defaultValue={getSelectMultiLabel(
                    getValues("qualifications_types")
                  )}
                />
              )}
              name="qualifications_types"
            />
            <ErrorMessage
              errors={errors}
              name="qualifications_types"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
      </Row>

      <Row className={styles.dividedRow}>
        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Validade:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              mask="11/11/1111"
              placeholder="DD/MM/AAAA"
              className={styles.miniInput}
              {...register("qualifications.dt_validate", {
                required: "Data de validade é obrigatória.",
                pattern: {
                  value: dateRegex,
                  message: "Data inválida.",
                },
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = dateMask(value);
              }}
            />
            <ErrorMessage
              errors={errors}
              name="qualifications.dt_validate"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Data Emissão:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              mask="11/11/1111"
              placeholder="DD/MM/AAAA"
              className={styles.miniInput}
              {...register("qualifications.dt_issue", {
                required: "Data de emissão é obrigatória.",
                pattern: {
                  value: dateRegex,
                  message: "Data inválida.",
                },
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = dateMask(value);
              }}
            />
            <ErrorMessage
              errors={errors}
              name="qualifications.dt_issue"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.mediumInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>
                Local Emissão:
              </Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              className={styles.inputLarge}
              {...register("qualifications.local", {
                required: "Local de emissão é obrigatório.",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="qualifications.local"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
      </Row>

      <Row className={styles.dividedRow}>
        <h1 className={styles.title}>RG</h1>
        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>
                Nº de Registro:
              </Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              placeholder="00.000.000-1"
              className={styles.inputLarge}
              {...register("id_card.doc_number", {
                pattern: {
                  value: RGRegex,
                  message: "Número de registro inválido",
                },
                required: "Número de registro é obrigatório.",
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = rgMask(value);
              }}
            />

            <ErrorMessage
              errors={errors}
              name="id_card.doc_number"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.mediumInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Órgão:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              className={styles.miniInput}
              {...register("id_card.issuing_body", {
                required: "Órgão emissor é obrigatório.",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="id_card.issuing_body"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Emissão:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              mask="11/11/1111"
              placeholder="DD/MM/AAAA"
              className={styles.miniInput}
              {...register("id_card.dt_issue", {
                required: "Data de emissão é obrigatória.",
                pattern: {
                  value: dateRegex,
                  message: "Data inválida.",
                },
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = dateMask(value);
              }}
            />
            <ErrorMessage
              errors={errors}
              name="id_card.dt_issue"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
      </Row>
    </div>
  );
}
