import React from "react";

import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Swal from "sweetalert2";
import RequiredTag from "../requiredTag/index.js";

import { CEPRegex } from "../../utils/regex.js";
import { cepMask } from "../../utils/maskedInputs";
import { getLabel } from "../../utils/arrayManipulation";
import { states_data } from "./selectData";

import styles from "./styles.module.scss";

import routerAPI from "../../services/routerAPI";

export default function AddressForm({
  // Formulário a ser utilizado no ./driverForm, especificamente a parte do endereço.
  register,
  control,
  errors,
  getValues,
  isNew,
  reset,
}) {
  const handleCEP = async (cepCode) => {
    //função para preencher automaticamente o endereço relacionado ao CEP digitado

    var str = cepCode.replace("-", "");

    routerAPI.brasilAPI.cepSearch
      .find(str)
      .then((res) => {
        reset({
          ...getValues(),
          address: {
            cep: cepCode,
            state: res.data.state,
            city: res.data.city,
            neighborhood: res.data.neighborhood,
            street: res.data.street,
          },
        });
      })
      .catch((err) => {
        Swal.fire({
          titleText: "CEP inválido!",
          text: "Tente novamente",
          icon: "error",
        });
      });
  };

  return (
    <div className={styles.new}>
      {" "}
      <Row className={styles.dividedRow}>
        <Row className={styles.smallInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>CEP:</Form.Label>
              <RequiredTag />{" "}
            </div>

            <Form.Control
              type="text"
              placeholder="99999-999"
              {...register("address.cep", {
                pattern: { value: CEPRegex, message: "CEP inválido" },
                required: "CEP é obrigatório.",
              })}
              onChange={({ target }) => {
                const { value } = target;
                target.value = cepMask(value);
              }}
              onBlur={({ target }) => handleCEP(target.value)}
            />

            <ErrorMessage
              errors={errors}
              name="address.cep"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
            {/* // onBlur={({ target }) => handleCEP(target.value)} */}
          </Form.Group>
        </Row>

        <Row className={styles.streetInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Rua:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              className={styles.inputLarge}
              {...register("address.street", {
                required: "Rua é obrigatória.",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="address.street"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>
        <Row className={styles.miniInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Número:</Form.Label>
              <RequiredTag />
            </div>
            <Form.Control
              type="text"
              {...register("address.number", {
                pattern: { value: /[0-9]$/i, message: "Não é um número." },
                required: "Número é obrigatório.",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="address.number"
              render={({ message }) => (
                <p className={styles.errorFlag}> {message} </p>
              )}
            />
          </Form.Group>
        </Row>

        <Row className={styles.miniInputRow}>
          <Form.Group as={Col} className={styles.inputRow}>
            <div className={styles.requiredTag}>
              <Form.Label className={styles.formText}>Complemento:</Form.Label>
            </div>
            <Form.Control
              type="text"
              placeholder="Ex: apt 110"
              {...register("address.complement")}
            />
          </Form.Group>
        </Row>
      </Row>
      <Row>
        <Form.Group as={Col} className={styles.inputRow}>
          <div className={styles.requiredTag}>
            <Form.Label className={styles.formText}>Bairro:</Form.Label>
            <RequiredTag />
          </div>
          <Form.Control
            type="text"
            {...register("address.neighborhood", {
              required: "Bairro é obrigatório.",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="address.neighborhood"
            render={({ message }) => (
              <p className={styles.errorFlag}> {message} </p>
            )}
          />
        </Form.Group>
      </Row>
      <Row className={styles.dividedRow}>
        <Form.Group as={Col} xs={7} className={styles.inputRow}>
          <div className={styles.requiredTag}>
            <Form.Label className={styles.formText}>Cidade:</Form.Label>
            <RequiredTag />
          </div>
          <Form.Control
            type="text"
            {...register("address.city", {
              required: "Cidade é obrigatória.",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="address.city"
            render={({ message }) => (
              <p className={styles.errorFlag}> {message} </p>
            )}
          />
        </Form.Group>

        <Form.Group as={Col} xs={4} className={styles.inputRow}>
          <div className={styles.requiredTag}>
            <Form.Label className={styles.formText}>Estado:</Form.Label>
            <RequiredTag />
          </div>
          <Controller
            control={control}
            {...register("address.state", {
              required: "Estado é obrigatório.",
            })}
            ref={null}
            render={({ field: { onChange, name } }) => (
              <Select
                placeholder="Selecione..."
                name={name}
                options={states_data}
                className="basic-select"
                classNamePrefix="select"
                onChange={(selectedOption) => {
                  onChange(selectedOption.value);
                }}
                defaultValue={
                  isNew
                    ? {
                        label: getValues("address.state"),
                        value: getValues("address.state"),
                      }
                    : {
                        label: getLabel(getValues("address.state"), "2"),
                        value: getValues("address.state"),
                      }
                }
              />
            )}
            name="address.state"
          />
          <ErrorMessage
            errors={errors}
            name="address.state"
            render={({ message }) => (
              <p className={styles.errorFlag}> {message} </p>
            )}
          />
        </Form.Group>
      </Row>
    </div>
  );
}
