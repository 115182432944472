import { Navbar, Nav } from "react-bootstrap";

import { logout } from "../../utils/login.js";

import styles from "./styles.module.scss";

function NavBar() {
  return (
    <>
      <div className={styles.navBar}>
        <div className={styles.navContent}>
          <p className={styles.brand} href="/home">
            Roteirizador
          </p>
          <button onClick={() => logout()} className={styles.logoutButton}>Logout</button>
        </div>
      </div>
    </>
  );
}

export default NavBar;
