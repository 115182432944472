import React, { useState, useEffect } from "react";

import { Container, Row } from "react-bootstrap";
import VehicleCard from "../../components/vehicleCard/index.js";
import VehicleForm from "../../components/registrationForm/vehicleForm.js";
import { ImPlus } from "react-icons/im";

import styles from "./styles.module.scss";

import routerAPI from "../../services/routerAPI";

const preloadedValues = {
  drivers: [],
};

function Vehicles() {
  // Galeria de ./DriverCards, e onde se faz o cadastro de novo motorista;
  const [show, setShow] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);

  useEffect(() => {
    routerAPI.listing.vehicle
      .search()
      .then((res) => setVehicleData(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container fluid className={styles.pageContainer}>
      <button className={styles.modalButton} onClick={() => setShow(true)}>
        <ImPlus className={styles.icon} />
      </button>
      <Row className={styles.cardGallery}>
        {vehicleData.map((item, index) => {
          return <VehicleCard item={item} index={index} key={index} />;
        })}
      </Row>
      <VehicleForm
        isNewVehicle={true}
        show={show}
        setShow={setShow}
        loadedValues={preloadedValues}
      />
    </Container>
  );
}

export default Vehicles;
