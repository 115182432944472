import { api } from "./api";

import axios from "axios";

const routerAPI = {
  login: {
    // request para login
    user: {
      send: (content) => {
        return api.post("login", content);
      },
    },
  },

  registration: {
    // POSTs/PATCHes
    physicalPerson: {
      send: (content) => {
        return api.post("persons", content);
      },
      addVehicles: (id, content) => {
        console.log(id, content);
        return content !== undefined
          ? api.patch(`/persons/${id}`, { vehicles: content })
          : api.patch(`/persons/${id}`, { vehicles: [] });
      },
      edit: (id, content) => {
        console.log("ID + Conteúdo a enviar ", id, content);
        return api.patch(`/persons/${id}`, content);
      },
    },
    vehicleDocument: {
      send: (content) => {
        return api.post("vehicles", content);
      },
      addDrivers: (id, content) => {
        console.log("ID + Conteúdo a enviar ", id, content);
        return content !== undefined
          ? api.patch(`/vehicles/${id}`, { drivers: content })
          : api.patch(`/vehicles/${id}`, { drivers: [] });
      },
      edit: (id, content) => {
        console.log("ID + Conteúdo a enviar ", id, content);
        return api.patch(`/vehicles/${id}`, content);
      },
    },
  },
  listing: {
    // GETs
    driver: {
      search: () => {
        return api.get(`/persons`);
      },
      pull: (id) => {
        return api.get(`/persons/${id}`);
      },
      sendAddress: (address, id) => {
        console.log(address);
        return api.post(`/persons/${id}`, address);
      },
    },
    vehicle: {
      search: () => {
        return api.get(`/vehicles`);
      },
      pull: (id) => {
        return api.get(`/vehicles/` + id);
      },
      sendAddress: (address, id) => {
        console.log(address);
        return api.post(`/vehicles/${id}`, address);
      },
    },
  },
  brasilAPI: {
    // endpoint do CEP chamado no ../components/registrationForm/addressForm
    cepSearch: {
      find: async (cepCode) => {
        console.log(process.env.REACT_APP_BRASIL_API + `cep/v1/${cepCode}`);
        return await axios.get(
          process.env.REACT_APP_BRASIL_API + `cep/v1/${cepCode}`
        );
      },
    },
  },
};

export default routerAPI;
