import React from "react";

import { Row, Col, Card } from "react-bootstrap";

import styles from "./styles.module.scss";

function MiniVehicleCard({ brand_model, licensePlate, model_year, color }) {
  return (
    <Card className={styles.card}>
      <Card.Body className={styles.cardBody}>
        <Row className={styles.cardRow}>
          <Col className={styles.picArea}>
            <img
              alt=""
              className={styles.profilePic}
              src="https://thispersondoesnotexist.com/image"
            />
          </Col>

          <Col xs={8} className={styles.cardText}>
            <Row>
              <p className={styles.cardTitle}>
                {brand_model} - {licensePlate}
              </p>
            </Row>
            <p className={styles.yearColor}>
              {model_year} | {color}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default MiniVehicleCard;
