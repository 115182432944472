import { React } from "react";

import { Row, Col, Card } from "react-bootstrap";
import { useNavigate, generatePath } from "react-router-dom";
import { Icon } from "@iconify/react";

import truckIcon from "../../assets/truck.jpeg";
import pickupIcon from "../../assets/pickup.jpeg";
import hatchIcon from "../../assets/hatch.jpeg";
import sedanIcon from "../../assets/sedan.jpeg";
import motoIcon from "../../assets/moto.jpeg";

import styles from "./styles.module.scss";

function VehicleCard({ item, index }) {
  function switchPicture(param) {
    switch (param) {
      case "caminhão":
        return truckIcon;
      case "pickup":
        return pickupIcon;
      case "hatch":
        return hatchIcon;
      case "sedan":
        return sedanIcon;
      case "moto":
        return motoIcon;
      default:
    }
  }

  const navigate = useNavigate();

  const handleProceed = (e) => {
    navigate(generatePath(`/vehiclePage/${item.id}`));
  };

  return (
    <Col
      className={styles.cardCol}
      xs={12}
      sm={12}
      md={11}
      lg={6}
      xl={5}
      xxl={4}
    >
      <Card onClick={(e) => handleProceed(e)} className={styles.card}>
        <Card.Img
          variant="top"
          className={styles.cardBanner}
          src="https://images.wallpaperscraft.com/image/single/texture_relief_3d_156496_1600x900.jpg"
        />
        <Card.Body>
          <Row>
            <Col>
              <div className={styles.crop}>
                <img
                  alt=""
                  className={styles.cardPicture}
                  src={switchPicture(item.type.toLowerCase())}
                />
              </div>
              <div className={styles.vehicleUsers}></div>
              <div className={styles.usersNumber}>
                <p>{item.drivers ? item.drivers.length : "no "} users</p>
              </div>
            </Col>
            <Col>
              <h3 className={styles.cardTitle}>{item.brand_model}</h3>
              <div className={styles.cardContent}>
                <div className={styles.cardText}>
                  <Icon icon="la:credit-card"></Icon>
                  <span> {item.licensePlate} </span>
                </div>
                <div className={styles.cardText}>
                  <Icon icon="carbon:spray-paint" flip="horizontal" />
                  <span> {item.color} </span>
                </div>
                <div className={styles.cardText}>
                  <Icon icon="ant-design:calendar-twotone" />
                  <span> {item.model_year} </span>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default VehicleCard;
