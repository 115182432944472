import React from "react";

import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RequiredTag from "../requiredTag/index.js";
import Register from "../register/index.js";

import {
  vehicleDocRegex,
  licensePltRegex,
  dateRegex,
} from "../../utils/regex.js";
import { cpfMask, dateMask } from "../../utils/maskedInputs.js";
import { isCPFValid } from "../../utils/validation";

import styles from "./styles.module.scss";

export default function VehicleDocForm({ register, errors }) {
  return (
    <div className={styles.new}>
      <Tabs
        fill
        justify
        defaultActiveKey="page_1"
        id="uncontrolled-tab-example"
        className={styles.formTab}
      >
        <Tab eventKey="page_1" title="Frente">
          <Row className={styles.dividedRow}>
            <Col className={styles.picColumn} xs={4}>
              <Form.Label className={styles.formText}>Foto Veículo</Form.Label>
              <Register />
            </Col>
            <Col xs={7} className={styles.contactColumn}>
              <Row>
                <Form.Group as={Col} className={styles.inputRow}>
                  <div className={styles.requiredTag}>
                    <Form.Label className={styles.formText}>
                      Órgão expedidor:{" "}
                    </Form.Label>
                    <RequiredTag />
                  </div>
                  <Form.Control
                    type="text"
                    {...register("issuing_body", {
                      required: "Órgão é obrigatório.",
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="issuing_body"
                    render={({ message }) => (
                      <p className={styles.errorFlag}> {message} </p>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className={styles.inputRow}>
                  <div className={styles.requiredTag}>
                    <Form.Label className={styles.formText}>
                      Número de registro:
                    </Form.Label>
                    <RequiredTag />
                  </div>
                  <Form.Control
                    type="text"
                    {...register("reg_number", {
                      pattern: {
                        value: vehicleDocRegex,
                        message: "Número de registro inválido",
                      },
                      required: "Número de registro é obrigatório.",
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="reg_number"
                    render={({ message }) => (
                      <p className={styles.errorFlag}> {message} </p>
                    )}
                  />
                </Form.Group>
              </Row>
            </Col>

            <Row className={styles.dividedRow}>
              <Form.Group as={Col} xs={2} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>Via:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  {...register("copy", {
                    pattern: { value: /[0-9]$/i, message: "Não é um número." },
                    required: "Via é obrigatória.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="copy"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} xs={4} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>RENAVAM:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  {...register("renavam", {
                    required: "RENAVAM é obrigatório.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="renavam"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} xs={3} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>RNTRC:</Form.Label>
                </div>
                <Form.Control type="text" {...register("rntrc")} />
              </Form.Group>

              <Form.Group as={Col} xs={3} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>
                    Exercício:
                  </Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  {...register("effective", {
                    pattern: {
                      value: /^([0-9]{4})$/i,
                      message: "Forneça um ano válido.",
                    },
                    required: "Ano de exercício é obrigatório.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="effective"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={7} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>Nome:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  {...register("name", {
                    required: "Nome é obrigatório.",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>

              {/* CódSegurança??? */}

              <Form.Group as={Col} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>CPF:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  placeholder="000.000.000-11"
                  {...register("cpf", {
                    required: "CPF é obrigatório.",
                    validate: (value) => isCPFValid(value) || "CPF é inválido.",
                  })}
                  onChange={({ target }) => {
                    const { value } = target;
                    target.value = cpfMask(value);
                  }}

                  //onBlur={({ target }) => handleCPF(target.value)}
                />

                <ErrorMessage
                  errors={errors}
                  name="cpf"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xs={5} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>Placa:</Form.Label>
                  <RequiredTag />
                </div>
                <Form.Control
                  type="text"
                  {...register("licensePlate", {
                    pattern: {
                      value: licensePltRegex,
                      message: "Placa inválida",
                    },
                    required: "Placa é obrigatória.",
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="licensePlate"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} xs={5} className={styles.inputRow}>
                <div className={styles.requiredTag}>
                  <Form.Label className={styles.formText}>
                    Placa Antiga:
                  </Form.Label>
                </div>
                <Form.Control
                  type="text"
                  {...register("old_licensePlt", {
                    pattern: {
                      value: licensePltRegex,
                      message: "Placa inválida",
                    },
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="old_licensePlt"
                  render={({ message }) => (
                    <p className={styles.errorFlag}> {message} </p>
                  )}
                />
              </Form.Group>
            </Row>
          </Row>
        </Tab>

        <Tab eventKey="page_2" title="Verso">
          <Row>
            <Form.Group as={Col} xs={8} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Chassi:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("chassis", {
                  required: "Chassi é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="chassis"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={4} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Tipo:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("type", {
                  required: "Tipo é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="type"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={6} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>
                  Tipo(s) de Combustível:
                </Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("fuel_type", {
                  required: "Combustível é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="fuel_type"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={6} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>
                  Marca e Modelo:
                </Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("brand_model", {
                  required: "Marca/Modelo é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="brand_model"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={3} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Fabricação:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("manufacturing_year", {
                  pattern: {
                    value: /^([0-9]{4})$/i,
                    message: "Forneça um ano válido.",
                  },
                  required: "Ano de fabricação é obrigatório.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="manufacturing_year"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={3} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Ano:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("model_year", {
                  pattern: {
                    value: /^([0-9]{4})$/i,
                    message: "Forneça um ano válido.",
                  },
                  required: "Ano do modelo é obrigatório.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="model_year"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={6} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>
                  CAP/POT/CIL:
                </Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("cap_pot_cil", {
                  required: "CAP/POT/CIL é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="cap_pot_cil"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} xs={5} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Categoria:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("category", {
                  required: "Categoria é obrigatória.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="category"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} xs={3} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Cor:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("color", {
                  required: "Cor é obrigatória.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="color"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Emissão:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                placeholder="DD/MM/AAAA"
                {...register("issuing_date", {
                  required: "Data é obrigatória.",
                  pattern: {
                    value: dateRegex,
                    message: "Data inválida.",
                  },
                })}
                onChange={({ target }) => {
                  const { value } = target;
                  target.value = dateMask(value);
                }}
              />
              <ErrorMessage
                errors={errors}
                name="issuing_date"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} xs={6} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>
                  Observações:
                </Form.Label>
              </div>
              <Form.Control type="text" {...register("observations")} />
              {/* 
              <ErrorMessage
                errors={errors}
                name="color"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              /> */}
            </Form.Group>

            <Form.Group as={Col} xs={6} className={styles.inputRow}>
              <div className={styles.requiredTag}>
                <Form.Label className={styles.formText}>Local:</Form.Label>
                <RequiredTag />
              </div>
              <Form.Control
                type="text"
                {...register("location", {
                  required: "Local é obrigatório.",
                })}
              />

              <ErrorMessage
                errors={errors}
                name="location"
                render={({ message }) => (
                  <p className={styles.errorFlag}> {message} </p>
                )}
              />
            </Form.Group>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
}
