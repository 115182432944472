import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import VehicleDocForm from "./vehicleDocForm";

import { AddToData } from "../../utils/registration";
import { formHelper } from "../../utils/objectManipulation.js";

import styles from "./styles.module.scss";

import RouterAPI from "../../services/routerAPI";

function DriverForm({ isNewVehicle, show, setShow, loadedValues, dataUpdate }) {
  const handleClose = () => setShow(false);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    // watch,
    // formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: loadedValues,
  });

  const SendData = (data) => {
    AddToData(data);
  };

  async function handleSend(obj) {
    // requisição de cadastro.
    formHelper(obj); // auxiliar para envio de formulários, deleta campos vazios.

    isNewVehicle // diferencia a requisição entre o POST e o PATCH
      ? await RouterAPI.registration.vehicleDocument
          .send(obj)
          .then((res) => {
            Swal.fire({
              titleText: "Sucesso",
              text: "Veículo cadastrado com sucesso! ",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              titleText: "Erro",
              text: "Houve um erro ao cadastrar o veículo, " + err.message,
              icon: "error",
            });
          })
      : await RouterAPI.registration.vehicleDocument
          .edit(loadedValues.id, obj)
          .then((res) => {
            Swal.fire({
              titleText: "Sucesso",
              text:
                "Dados alterados com sucesso para " +
                loadedValues.brand_model +
                "!",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              titleText: "Erro",
              text:
                "Houve um erro ao alterar os dados de " +
                loadedValues.brand_model +
                " " +
                err.message,
              icon: "error",
            });
          });

    dataUpdate(obj);
  }

  useEffect(() => {
    reset(loadedValues);
  }, [reset, loadedValues]);

  return (
    <>
      <Modal
        contentClassName={styles.modalContent}
        show={show}
        size={"lg"}
        onHide={handleClose}
        dialogClassName={styles.modal}
        scrollable={true}
      >
        <Modal.Header className={styles.modalHeader} closeButton>
          <Modal.Title>
            {isNewVehicle
              ? "Cadastro de Novos Veículos"
              : "Alteração de Dados do Veículo"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form
            noValidate
            className={styles.formArea}
            onSubmit={handleSubmit(SendData)}
          >
            <VehicleDocForm
              register={register}
              control={control}
              errors={errors}
              getValues={getValues}
              isNew={isNewVehicle}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="primary" onClick={handleSubmit(handleSend)}>
            {isNewVehicle ? "Finalizar Cadastro" : "Salvar alterações"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DriverForm;
