import { React, useState } from "react";

import { Container } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";

import { login } from "../../utils/login.js"; //seta um token no localStorage para ver que o usuário está logado.

import styles from "./styles.module.scss";

// --! comentado para uso quando API e endpoint de login estiverem disponíveis
// import routerAPI from "../../services/routerAPI";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function testeLogin() {
    login("teste");
  }

  // --! comentado para uso quando API e endpoint de login estiverem disponíveis

  // const handleSubmit = async (e) => {
  //   console.log(email + password);
  //   e.preventDefault();
  //   // enviar ao servidor email e senha;
  //   // e também ao localStorage?

  //   routerAPI.login.user
  //     .send({
  //       email: `${email}`,
  //       password: `${password}`,
  //     })
  //     .then((response) => {
  //       console.log(response.data, "veio isso");
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       alert(error.response.data.message);
  //     });
  // };

  return (
    <Container fluid className={styles.pageContainer}>
      <div className={styles.whiteCard}>
        <div className={styles.logoCard}>
          <FaUserCircle className={styles.icon} />
        </div>
        <div className={styles.loginCard}>
          <h1>Login</h1>
          <form onSubmit={testeLogin}>
            {/* mudar de função teste para handleSubmit quando tiver API */}
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              placeholder="Email"
              onChange={({ target }) => setEmail(target.value)}
            ></input>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              placeholder="Senha"
              onChange={({ target }) => setPassword(target.value)}
            ></input>
            <button
              onClick={login()}
              type="submit"
              className={styles.buttonSubmit}
            >
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default Login;
