// Componente de upload de imagem, apenas a "casca" por enquanto. Renderiza a imagem selecionada em URL temporária.

import React, { useState } from "react";

import styles from "./styles.module.scss";

const Register = () => {
  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  return (
    <div>
      <label htmlFor="upload-button">
        {image.preview ? (
          <img
            src={image.preview}
            className={styles.previewPic}
            alt="Arquivo não é imagem."
          />
        ) : (
          <>
            <span className="fa-stack fa-2x mt-3 mb-2">
              <i className="fas fa-circle fa-stack-2x" />
              <i className="fas fa-store fa-stack-1x fa-inverse" />
            </span>
            <h5 className={styles.uploadButton}>Selecionar foto</h5>
          </>
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        onChange={handleChange}
        className={styles.deactivatedButton}
      />
      <br />
    </div>
  );
};

export default Register;
