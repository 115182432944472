//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// recebe o conteúdo de um campo e envia o nome do campoya;
// ex.: 'name: Nilmer'; recebe Nilmer + o objeto como parâmetro e retorna 'name'
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const getKeyByValue = function (obj, value) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (obj[prop] === value) return prop;
    }
  }
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// auxiliar para envio de formulários, deleta campos vazios:
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const formHelper = (obj) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === "" || value === undefined || value === null) {
      delete obj[key];
    }

    if (value && typeof value === "object") {
      if (value === "") {
        delete obj[key];
      }
      if (Object.keys(value).length === 0) delete obj[key];
    }

    if (value && typeof value === "object") {
      formHelper(value);
    }
  }
  return obj;
};

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// auxiliar para selects múltiplos enviarem apenas 'value', deleta campos vazios:
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const labelRemover = (selectedOption) => {
  var finalOption = [];
  for (var key in selectedOption) {
    finalOption.push(selectedOption[key].value);
  }
  return finalOption;
};
